<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="请选择" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h1>Whistle隐私及数据使用政策</h1>
		<p>隐私及数据使用政策是Whistle企业使命的一部分，是赋予会员更开放、更密切的了解Whistle并使用Whistle平台的基础。本政策对我们收集什么样的信息及其使用和分享方式进行了说明。您可以在隐私基本信息处了解其他工具和信息。</p>
		<p>在您查看我们的政策时，请记住这些政策适用于所有 Whistle平台旗下品牌、产品和未设独立隐私政策或与本政策相关的服务，我们将其称为“Whistle服务”或“服务”。</p>
		<h2>第一条 我们收集哪些信息？</h2>
		<p>我们将根据您使用的服务从您那里收集不同的信息或关于您的信息。</p>
		<p>1、您执行的操作和提供的信息。</p>
		<p>
			我们收集您在使用我们的服务时提供的内容和其他信息，包括在注册帐户、创建或分享消息，以及与他人发消息或交流时提供的信息。其中包括您所提供的内容信息或关于您的信息，例如照片的位置信息或文件的创建日期。我们还收集有关您如何使用我们服务的信息，例如您查看或参与的内容类型或此类活动的频率及持续时间。
		</p>
		<p>2、其他人执行的操作和提供的信息。</p>
		<p>我们还收集其他人在使用我们的服务时提供的内容和信息，其中包括关于您的信息，例如，当他们分享您的照片、发信息给您，或上传、同步或导入您的联系信息时提供的信息。</p>
		<p>3、您的人际网络和关系网络。</p>
		<p>我们收集有关您的联系人和您所加入小组的信息，以及您与他们的互动程度信息，例如与您交流最多的个人或您喜欢分享的小组。我们还收集您从设备上传、同步或导入的联系信息（例如通讯录）。</p>
		<p>4、支付信息。</p>
		<p>
			如果您使用我们的服务进行购买或财务交易（例如您在Whistle上购物、进行游戏内购或进行捐赠），我们会收集有关这些购买或交易的信息。这包括您的支付信息，例如您的信用卡或借记卡号码及卡的其他信息、其他账户和身份验证信息、账单、配送和联系方式。
		</p>
		<p>5、设备信息。</p>
		<p>
			我们还将根据您授予的权限，从您安装或访问我们服务的电脑、手机或其他设备收集信息或收集与这些设备相关的信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们在这些设备上为您提供一致的服务。以下是一些我们收集的设备信息示例：
			1）属性，例如操作系统、硬件版本、设备设置、文件和软件名称及类型、电池和信号强度和设备标识符。
			2）装置位置，包括特定的地理位置，例如通过 GPS、蓝牙或 WiFi 信号获得的位置信息。
			3）连接信息，例如您的移动运营商或 ISP 名称、浏览器类型、语言和时区、手机号码和 IP 地址。
			4）来自使用我们服务的网站和应用程序的信息。当您访问或使用第三方网站和应用程序（这些网站和应用程序使用我们的服务）时，我们将收集信息，例如当它们提供我们的“赞”按钮或“Whistle登录”或使用我们的衡量服务和广告服务时。收集的信息包括您访问的网站和应用程序的信息、您在这些网站和应用程序上使用的我们的服务，以及应用程序或网站开发者或发布商向您或我们提供的信息。
			5）来自第三方合作伙伴的信息。我们从第三方合作伙伴接收有关您和您在使用和未使用Whistle期间的活动的信息，例如当我们与合作伙伴共同提供服务时从该合作伙伴接收的信息，或从广告主接收的关于您的体验或您与其互动的信息。
			6）Whistle旗下公司。对于Whistle拥有或运营的公司，我们将根据他们的相关条款和政策从他们那里接收有关您的信息。了解更多有关这些公司及其隐私政策的信息。
		</p>
		<h2>第二条 我们如何使用这些信息？</h2>
		<p>我们热衷于为用户创建有吸引力的和量身定制的体验。我们利用所获得的一切信息来帮助我们提供服务以及服务支持。具体操作：</p>
		<p>1）提供、完善和开发服务。利用这些信息，我们能够了解您如何使用我们的服务并与之互动，以及您如何在使用和未使用Whistle服务期间与已建立联系和感兴趣的人与事物互动，从而使我们能够为您提供服务、个性化内容以及建议。</p>
		<p>2）我们还利用这些信息为您提供捷径和建议。例如，通过将您朋友的照片与我们所收集的您曾被圈出的照片进行比较，我们能够建议您的朋友在照片中将您圈出。如果为您开启了此项功能，您可以使用“时间线和圈人”设置来控制是否允许我们建议其他用户在照片中将您圈出。</p>
		<p>3）获得位置信息后，我们会使用这些信息为您和其他人量身定制服务，例如，帮助您签到并找到您所在区域的本地活动或优惠，或告诉好友您在附近。</p>
		<p>4）我们会进行调查和研究、测试开发中的功能并分析我们拥有的信息，以便评估和改进产品和服务、开发新的产品或功能以及开展审计和故障排除活动。</p>
		<p>5）与您交流。我们使用您的信息向您发送营销信息、与您沟通我们的服务并让您了解我们的政策和条款。当您与我们联系时我们还使用您的信息给您答复。</p>
		<p>6）显示和衡量广告及服务。我们利用所拥有的信息改善我们的广告和衡量系统，以便我们可以在使用和未使用Whistle服务期间向您显示相关的广告，并衡量广告及服务的有效性和覆盖情况。了解更多关于我们线上服务中的广告，并了解如何控制您的信息的使用方式，以便个性化您所看到的广告。</p>
		<p>7）提升安全保障。我们使用所拥有的信息来帮助验证帐户和活动，提升您在使用和未使用Whistle服务期间的安全保障，例如调查可疑的活动或违反我们条款和政策的行为。我们通过设立工程师团队、采用自动化系统以及诸如加密和机器学习等先进技术来竭力保障您的帐户安全。我们还提供易于使用的安全工具，为您的帐户添加额外的安全保障。有关提升Whistle安全性的更多信息，请访问Whistle安全（帮助）中心。我们使用Cookie 和类似技术来提供并支持我们的服务，这些技术的使用情况在我们本部分政策中有所罗列和描述。阅读我们的 Cookie 政策了解详情。</p>
		<h2>第三条 这些信息将被如何分享？</h2>
		<p>在我们的服务中分享用户通过我们的服务建立联系和相互分享。我们通过以下方式分享您的信息，以实现该目的：</p>
		<p>1、您分享和交流的用户。当您通过我们的服务分享和交流时，您会选择能够看到您所分享内容的受众。例如，当您在Whistle发帖时，您会选择相关帖子的受众，例如一个自定义的个人小组、所有好友或某个小组的成员。</p>
		<p>2、公开信息是指您公开分享的任何信息，包括公开档案中的信息，或在Whistle主页或其他公共论坛上分享的内容。任何人都可通过我们的线上和线下服务获得公开信息，并可以通过在线搜索引擎、API和线下媒体（例如电视）查看或访问这些信息。</p>
		<p>3、在某些情况下，您分享和交流的人有可能下载这些信息，或通过我们的服务及其他服务与他人再次分享这些信息。当您对某个人的帖子发表评论或对其在Whistle上分享的内容点赞时，由该人决定哪些受众可以看到您的评论或赞。如果他们选择的受众是公开，那么您的评论也将被公开。</p>
		<p>4、看到其他人分享有关您的信息的用户。其他人可能会使用我们的服务与他们选择的受众分享有关您的信息。例如，这些用户可能会分享您的照片、在一个帖子中提及您或将您圈出，或分享您分享给他们的信息。如果您对某用户发布的帖子有顾虑，社交报告可以帮助您快速轻松地向您信任的人寻求帮助。详细了解。</p>
		<p>5、整合或使用我们服务的第三方应用程序和网站。对于使用或集成我们服务的第三方应用程序、网站或其他使用服务，当您在使用时，他们可能会收到您发布或分享的信息。例如，当您在网站上与Whistle好友玩游戏或使用Whistle的“评论”或“分享”按钮时，游戏开发人员或相关网站可能会获取关于您在游戏中的活动的信息，或接收您发布的评论或从他们的网站分享到Whistle的链接。此外，当您下载或使用这类第三方服务时，他们可以访问您的公开档案，其中包括您的用户名或用户 ID、年龄段和国家/语言、好友列表以及任何您与他们分享的信息。这些应用程序、网站或整合服务收集的信息受其自身的条款和政策约束。 了解更多有关如何控制您或其他人通过这些应用程序和网站分享的与您相关的信息。</p>
		<p>6、在Whistle旗下公司分享。我们在Whistle旗下公司分享我们拥有的有关您的信息。了解更多关于Whistle旗下公司的信息。</p>
		<p>7、新的所有者。如果我们的全部或部分服务或资产的所有权或控制权发生变更，我们可能会将您的信息转移给新的所有者。</p>
		<p>8、与第三方合作伙伴和客户分享我们与帮助我们提供及改善服务或使用广告或相关产品的第三方公司合作，以便我们运营公司和为世界各地的用户提供免费服务。 以下是我们会与其分享有关您的信息的第三方类型：</p>
		<p>
			1）广告、衡量和分析服务（仅限非个人身份信息）。我们希望我们的广告像我们服务中的其他内容一样具有相关性和趣味性。考虑到这一点，我们使用所拥有的信息向您展示相关的广告。除非得到您的许可，否则我们不会将您的个人身份信息（指可以识别您身份的信息，例如姓名或电子邮箱，通过这些信息可以联系您或识别您的身份）与提供广告、衡量和分析服务的合作伙伴分享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。例如，只有在广告主同意遵守我们的广告发布准则后，我们才可能会告诉广告主他们广告的效果如何，或者有多少人看了他们广告或在看到广告后安装了应用程序，或者向这些合作伙伴提供不能识别个人身份的人口统计信息，帮助他们了解其受众或顾客。
			2）供应商、服务提供商和其他合作伙伴。我们将信息发送给在中国范围支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、促进支付或进行学术研究和调查。这些合作伙伴必须根据本数据政策及我们与之签署的协议严格遵守保密义务。
		</p>
		<h2>第四条 如何管理或删除有关我的信息？</h2>
		<p>您可以通过活动日志管理您在使用Whistle时分享的内容和信息。您还可以通过信息下载工具下载与您的Whistle相关的信息。 我们将保存数据，直至其不再为您和其他人（包括以上描述的用户）提供产品和服务所必须的内容为止。与您帐户相关的信息将会保留至您的帐户被删除为止，除非我们不再需要这些数据来提供产品和服务。</p>
		<p>您可以随时删除您的帐户。当您删除自己的帐户后，我们会删除您发布的内容，例如，照片和状态更新。如果您不想删除自己的帐户，但想暂时停止使用 Whistle，您可以停用帐户。要详细了解停用或删除帐户，请点击此处。请记住，别人分享的与您相关的信息不是您帐户信息的一部分，因此当您删除帐号时这些信息不会被删除。</p>
		<h2>第五条 我们如何应对法律要求或预防伤害？</h2>
		<p>如果有充分的理由相信法律要求我们这样做，那么应法律要求（如搜查令、庭谕或传票），我们可能访问、保留和分享您的信息。这可能包括来自中国以外司法管辖区的法律要求，只要我们确信回应系经该司法管辖区法律要求、影响该司法管辖区内的用户并符合国际认可的标准。我们也会访问、保存并分享信息，只要我们有充分的理由相信有必要：对欺诈和其它非法行为进行侦查、预防和应对，以保护我们自己、您以及其他人（作为调查的一部分）；以防止死亡或严重人身伤害。例如，我们可能向第三方合作伙伴提供有关您的帐户可靠性的信息，以防你在使用和未使用Whistle服务期间发生欺诈和滥用。如果我们收到的有关您的信息，包括涉及使用Whistle进行购买相关的财务交易数据，为法律要求或义务、政府调查、涉及可能违反我们的条款或政策的调查、或预防伤害等方面的内容，则可能需要在一段较长时间内予以访问、处理和保留。我们也会将由于违反我们的条款而受到禁用的帐户信息保留至少一年，以防止重复违规或侵犯我们条款的行为。Whistle可能在旗下公司内部或与第三方分享信息以实现本政策所述目的。</p>
		<h2>第六条 我们将如何通知您本政策发生变更？</h2>
		<p>我们会在修订此政策之前通知您，让您在继续使用我们的服务之前可以查看和评论修订的政策。如需详细了解有关Whistle如何处理隐私的信息，请访问网站法律声明、隐私声明、商标声明和服务条款。 </p>
		<p>最后修订日期：2023 年 12月15日</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/PrivacyPolicyZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/PrivacyPolicyEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/PrivacyPolicyZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/PrivacyPolicyEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/PrivacyPolicyKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/PrivacyPolicyJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h2 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
</style>